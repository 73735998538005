<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="12" md="4">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.fullName)"
            :variant="`light-${resolveUserRoleVariant(userData.role_name)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.display_name || userData.fullName }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: userData.uid } }"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-form class="mt-1">
      <b-row>
        <b-col cols="12" md="4" v-if="userData.accountId">
          <b-form-group
            label="Jira Account Id"
            label-for="jira-account-id"
            :title="userData.accountId"
          >
            <b-form-input
              id="jira-account-id"
              v-model="userData.accountId"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="First Name" label-for="first-name">
            <b-form-input
              id="first-name"
              v-model="userData.first_name"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Last Name" label-for="last-name">
            <b-form-input
              id="last-name"
              v-model="userData.last_name"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" readonly />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Company Name" label-for="company-name">
            <b-form-input
              id="company-name"
              v-model="userData.company_name"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Company Website" label-for="company-website">
            <b-form-input
              id="company-website"
              v-model="userData.company_website"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group
            label-for="company_address_lines1"
            label="Company Address"
          >
            <b-form-input
              id="company_address_lines1"
              v-model="userData.company_address_lines1"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" v-if="userData.company_address_lines2">
          <b-form-group
            label-for="company_address_lines2"
            label="Company Address1"
          >
            <b-form-input
              id="company_address_lines2"
              v-model="userData.company_address_lines2"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" v-if="userData.company_address_lines3">
          <b-form-group
            label-for="company_address_lines3"
            label="Company Address2"
          >
            <b-form-input
              id="company_address_lines3"
              v-model="userData.company_address_lines3"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Role" label-for="role">
            <b-form-input id="role" v-model="userData.role_name" readonly />
          </b-form-group>
        </b-col>
      </b-row>

      <div
        class="d-flex justify-content-end"
        v-if="
          !userData.accountId && loggedInUserData.role_name == 'GLOBAL_ADMIN'
        "
      >
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="addAccountId(userData.uid)"
        >
          <div class="d-flex align-items-center">
            <b-spinner small label="Text Centered" v-if="isLoading"></b-spinner>
            <span class="button-title">Add Jira AccountId</span>
          </div>
        </b-button>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useUsersList from "../users-list/useUsersList";
import axios from "@axios";
import router from "@/router";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BSpinner,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { resolveUserRoleVariant } = useUsersList();
    const isLoading = ref(false);
    const loggedInUserData = JSON.parse(localStorage.getItem("userData"));
    const toast = useToast();

    const addAccountId = (id) => {
      isLoading.value = true;
      axios
        .put(`/user/jira_account_id/${id}`)
        .then((response) => {
          if (response) {
            isLoading.value = false;
            toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success `,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Jira accountId added successfully`,
              },
            });
            router.push("/apps/users/list");
          }
        })
        .catch(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `Jira accountId could not added`,
            },
          });
        });
    };

    return {
      isLoading,
      loggedInUserData,
      addAccountId,
      avatarText,
      resolveUserRoleVariant,
    };
  },
};
</script>
